:root {
  --container-width: 1170px;
}

body {
  --header-height: 5.9rem;
  --header-border-height: 6px;
  --announcement-height: 0px;
  --page-block-start: calc(var(--header-height) + var(--announcement-height) - var(--header-border-height));
}

@media (min-width: 900px) {
  body {
    --header-height: 5rem;
  }
}
.course-banner {
  --bgcolor: #65bef6;
  --iconcircle-size: 72px;
  background: var(--bgcolor);
  color: white;
}
@media (min-width: 600px) {
  .course-banner {
    --iconcircle-size: 148px;
  }
}

.course-banner-title {
  background-color: rgba(0, 0, 0, 0.2);
}
.course-banner-title .container {
  display: grid;
  grid-template-columns: var(--iconcircle-size) 1fr;
  align-items: start;
  gap: 0.35em;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media (min-width: 600px) {
  .course-banner-title .container {
    align-items: center;
    gap: 0 1rem;
  }
}
.course-banner-title .iconcircle {
  width: var(--iconcircle-size);
  height: var(--iconcircle-size);
}
.course-banner-title .kicker {
  color: #9cfff2;
}
.course-banner-title h1 {
  font-family: "Dosis", sans-serif;
  font-size: 2em;
  line-height: 1.2;
  margin: 0;
  color: inherit;
}
@media (min-width: 600px) {
  .course-banner-title h1 {
    font-size: 3em;
  }
}
.course-banner-title .badges {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5em 0 0;
  gap: 0.35em;
}

@media (min-width: 760px) {
  .course-banner-title:has(.course-format) .container {
    grid-template-columns: var(--iconcircle-size) 10fr 1fr;
  }
}

.course-format {
  justify-self: start;
  grid-column: 2/3;
  padding-block: 0.5em;
  font-size: 0.8em;
}
@media (min-width: 760px) {
  .course-format {
    grid-column: 3/4;
    font-size: 1em;
  }
}

.course-format .button {
  font-size: 0.9em;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: #9cfff2;
}

.course-banner-intro .container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0 1rem;
  padding-top: 1rem;
  padding-bottom: 6rem;
}
@media (min-width: 760px) {
  .course-banner-intro .container {
    padding-top: 0;
    grid-template-columns: 5fr 7fr;
  }
}
.course-banner-intro h2 {
  color: inherit;
}
.course-banner-intro h2::after {
  background-color: #fff;
  opacity: 0.6;
}
@media (min-width: 600px) {
  .course-banner-intro .image {
    max-width: 60%;
    margin: auto;
  }
}
@media (min-width: 760px) {
  .course-banner-intro .image {
    max-width: none;
    margin-top: -3rem;
  }
}
@media (min-width: 760px) {
  .course-banner-intro .content {
    padding-top: 2rem;
  }
}

.upcoming-courses {
  margin-top: -5rem;
}
.upcoming-courses .box-white {
  padding: 0;
}

.course-tabs {
  width: 100%;
  background: #f7f7f7;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  align-items: flex-end;
  padding: 1em 1em 0;
  margin: 0;
}
.course-tabs > li {
  flex: 1;
  border-radius: 0.5em 0.5em 0 0;
  margin: 0;
  padding: 0;
}
.course-tabs > li > a {
  display: block;
  opacity: 0.8;
  text-transform: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  padding: 0.5em 1em;
  font-size: 1.25em;
  transition: none;
}
@media (min-width: 600px) {
  .course-tabs > li > a {
    flex-direction: row;
  }
}
.course-tabs li.active {
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  position: relative;
  top: 1px;
}
.course-tabs li.active > a {
  color: #12243a;
  opacity: 1;
  border: none;
}

.course-tabs + .tab-content,
.course-tabs-content {
  position: relative;
  width: 100%;
}
.course-tabs + .tab-content .tab-pane,
.course-tabs-content .tab-pane {
  padding: 1em 8%;
}
.course-tabs + .tab-content h2,
.course-tabs-content h2 {
  text-align: center;
  color: inherit;
}

.course-formats {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1em;
  padding: 0 8%;
}

.course-formats-heading {
  flex: 1 1 300px;
}

.course-formats-tabs {
  flex: 2 0 250px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 0.75em;
}
.course-formats-tabs li {
  flex: 1;
}
.course-formats-tabs a {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: center;
  text-align: center;
  color: #4c4d52;
  font-size: 0.9em;
  text-decoration: none;
  padding: 0.75em 0;
  box-shadow: 0 0 0 1px #fff;
}
.course-formats-tabs a:hover {
  border-color: #65bef6;
}
.course-formats-tabs img {
  filter: grayscale(100%);
  opacity: 0.5;
}
.course-formats-tabs .active a {
  border-color: #65bef6;
  box-shadow: 0 0 0 1px #65bef6;
  background-image: linear-gradient(180deg, #FFFFFF 0%, #F9F9F9 89%, #F2F2F2 100%);
}
.course-formats-tabs .active a .button {
  background-color: transparent;
  color: #65bef6 !important;
}
.course-formats-tabs .active a .button::after {
  content: "ed";
}
.course-formats-tabs .active a img {
  filter: initial;
  opacity: 1;
}

.course-about .content-sidebar {
  gap: 2em;
}
.course-about .bg-light-grey-mountains {
  padding-bottom: 10em;
}
.course-about .box h3 {
  color: #4c4d52;
}
.course-about h2:first-child,
.course-about h3:first-child {
  margin-top: 0;
}

.course-testimonials-avatars {
  padding-top: 2em;
}
.course-testimonials-avatars .h1 {
  color: #fff;
}

.testimonial-avatars-slider {
  margin-bottom: 1em;
  margin-top: -4em;
  position: relative;
  top: 3.5em;
  z-index: 10;
}
.testimonial-avatars-slider .avatar-circle {
  --avatar-size: 4rem;
  border: 4px solid #fff;
  box-shadow: 2px 4px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  position: relative;
}
@media (min-width: 600px) {
  .testimonial-avatars-slider .avatar-circle {
    --avatar-size: 6rem;
  }
}
@media (min-width: 760px) {
  .testimonial-avatars-slider .avatar-circle {
    --avatar-size: 8rem;
  }
}
.testimonial-avatars-slider .avatar-circle::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  opacity: 0.5;
}

.slick-current .avatar-circle {
  border-color: #000;
}
.slick-current .avatar-circle::after {
  opacity: 0;
}

.course-testimonials .course-testimonials-avatars + .container {
  overflow: hidden;
}

.testimonials-slider .slick-next {
  margin-right: -10%;
  right: 0;
  display: none !important;
}
.testimonials-slider .slick-prev {
  margin-left: -10%;
  left: 0;
  display: none !important;
}
@media (min-width: 760px) {
  .testimonials-slider .slick-next, .testimonials-slider .slick-prev {
    display: inline-block !important;
  }
}

.course-testimonials .box {
  background-color: #edf8ff;
}
.course-testimonials .box * {
  max-width: none;
}

.how-it-works {
  background-color: #fafafa;
}

.how-timeline {
  font-size: 0.9em;
  padding-top: 199px;
  position: relative;
  background-image: url(/mgs_assets/images/v4/pages/training/how-chevrons.svg), url(/mgs_assets/images/v4/pages/training/how-chevrons.svg);
  background-repeat: no-repeat, no-repeat;
  background-position: -10% 1em, 110% 1em;
}
@media (min-width: 900px) {
  .how-timeline::before {
    content: "";
    width: 100%;
    height: 4px;
    background-color: #65bef6;
    position: absolute;
    top: 199px;
    left: 0;
  }
  .how-timeline > .container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }
}

.how-timeline-section {
  --border-color: #00c3a9;
  border-left: 4px solid var(--border-color);
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-bottom: 3em;
  position: relative;
}
@media (min-width: 900px) {
  .how-timeline-section {
    padding-bottom: 0;
  }
}
.how-timeline-section::before {
  content: "";
  width: 2em;
  height: 2em;
  border-radius: 50%;
  border: 4px solid var(--border-color);
  background-color: #fafafa;
  position: absolute;
  top: -1em;
  left: -1em;
  margin-left: -6px;
  margin-top: -3px;
}
.how-timeline-section h3 {
  text-transform: uppercase;
  color: #a6a6a6;
  font-size: 1.4em;
  margin: 0.75em 0;
}
@media (min-width: 900px) {
  .how-timeline-section h3 {
    margin: 1.5em 0 0.75em;
  }
}
.how-timeline-section h3 strong {
  color: var(--border-color);
  font-weight: 900;
}

.how-timeline-image {
  height: 195px;
}
@media (min-width: 900px) {
  .how-timeline-image {
    margin-top: -195px;
  }
}
.how-timeline-image img {
  max-height: 100%;
  display: block;
  margin: auto;
}

.directory {
  background-image: url(/mgs_assets/images/v4/pages/training/bg-team.svg);
  background-position: 0 100%;
  background-repeat: repeat-x;
  padding-bottom: 10em;
}

.directory-photos {
  display: flex;
  align-content: center;
  justify-content: center;
  margin: -6em 0 0;
}

.directory-photos img {
  transform: rotate(-1deg);
  background: #D8D8D8;
  border: 0.4em solid #fff;
  box-shadow: 0 0 0 1px #e7e7e7, 0 5px 0 0 rgba(0, 0, 0, 0.2);
  max-width: 18%;
  margin: 0 -0.25em;
  aspect-ratio: 1/1;
  object-fit: cover;
  position: relative;
}
.directory-photos img:nth-child(2) {
  transform: rotate(3deg);
  top: -1em;
}
.directory-photos img:nth-child(3) {
  transform: rotate(-5deg);
}
.directory-photos img:nth-child(4) {
  transform: rotate(7deg);
}
.directory-photos img:nth-child(5) {
  transform: rotate(-3deg);
  top: -0.5em;
}

.faq-wide .faq-group {
  display: flex;
  align-items: start;
  flex-wrap: wrap;
  gap: 0 2em;
  padding-top: 0.5em;
  border-top: 2px solid #d5efff;
}
.faq-wide .faq-group + .faq-group {
  margin-top: 2em;
}
.faq-wide .faq-group-heading {
  margin: 0.5em 0;
  flex: 1 0 10ch;
  font-size: 1.2em;
  font-weight: 400;
  color: #4797eb;
}
.faq-wide .faq-group-questions {
  flex: 4;
  min-width: 300px;
}