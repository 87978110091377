@import "../../node_modules/breakpoint-sass/stylesheets/breakpoint";
@import "../base/variables";
@import "../utils/mixins";

// Banners
// ----------------------------------------------------------------

.course-banner {
  --bgcolor: #65bef6;
  --iconcircle-size: 72px;

  @include breakpoint($med) {
    --iconcircle-size: 148px;
  }

  background: var(--bgcolor);
  color: white;
}

.course-banner-title {
  background-color: rgba(0,0,0,.2);

  .container {
    display: grid;
    grid-template-columns: var(--iconcircle-size) 1fr;
    align-items: start;
    gap: .35em;
    padding-top: 1rem;
    padding-bottom: 1rem;

    @include breakpoint($med) {
      align-items: center;
      gap: 0 1rem;
    }
  }

  .iconcircle {
    width: var(--iconcircle-size);
    height: var(--iconcircle-size);
  }

  .kicker {
    color: palette(teal, light);
  }

  h1 {
    font-family: $heading-font-alt;
    font-size: 2em;
    line-height: 1.2;
    margin: 0;
    color: inherit;
    
    @include breakpoint($med) {
      font-size: 3em;
    }
  }

  .badges {
    display: flex;
    flex-wrap: wrap;
    padding: .5em 0 0;
    gap: .35em;
  }
}

.course-banner-title:has(.course-format) .container {
  @include breakpoint($med-lg) {
    grid-template-columns: var(--iconcircle-size) 10fr 1fr;
  }
}
.course-format {
  justify-self: start;
  grid-column: 2/3;
  padding-block: .5em;
  font-size: 0.8em;

  @include breakpoint($med-lg) {
    grid-column: 3/4;
    font-size: 1em;
  }
}
.course-format .button {
  font-size: 0.9em;
  background-color: rgba(255,255,255,.1);
  border-color: palette(teal, light);
}

.course-banner-intro {
  .container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0 1rem;
    padding-top: 1rem;
    padding-bottom: 6rem;
    
    @include breakpoint($med-lg) {
      padding-top: 0;
      grid-template-columns: 5fr 7fr;      
    }
  }

  h2 {
    color: inherit;
    &::after {
      background-color: #fff;
      opacity: 0.6;
    }
  }

  .image {
    @include breakpoint($med) {
      max-width: 60%;
      margin: auto;
    }
    @include breakpoint($med-lg) {
      max-width: none;
      margin-top: -3rem;
    }
  }

  .content {
    @include breakpoint($med-lg) {
      padding-top: 2rem;
    }
  }
}


// Upcoming Courses
// ----------------------------------------------------------------

.upcoming-courses {
  margin-top: -5rem;

  .box-white {
    padding: 0;
  }
}

// Course Tabs
.course-tabs {
  width: 100%;
  background: #f7f7f7;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  align-items: flex-end;
  padding: 1em 1em 0;
  margin: 0;

  > li {
    flex: 1;
    border-radius: .5em .5em 0 0;
    margin: 0;
    padding: 0;
  }

  > li > a {
    display: block;
    opacity: 0.8;
    text-transform: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: .5em;
    padding: .5em 1em;
    font-size: 1.25em;
    transition: none;

    @include breakpoint($med) {
      flex-direction: row;
    }
  }

  li.active {
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,0.2);
    position: relative;
    top: 1px;
  }
  li.active > a {
    color: #12243a;
    opacity: 1;
    border: none;
  }
}

.course-tabs + .tab-content,
.course-tabs-content {
  // background-color: #fff;
  position: relative;
  width: 100%;

  .tab-pane {
    padding: 1em 8%;
  }

  h2 {
    text-align: center;
    color: inherit;
  }
}

// Course Formats
// ----------------------------------------------------------------

.course-formats {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1em;
  padding: 0 8%;
}
.course-formats-heading {
  flex: 1 1 300px;
}
.course-formats-tabs {
  flex: 2 0 250px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: .75em;

  li {
    flex: 1;
  }

  a {
    display: flex;
    flex-direction: column;
    gap: .5em;
    align-items: center;
    text-align: center;
    color: $base-font-color;
    font-size: 0.9em;
    text-decoration: none;
    padding: .75em 0;
    box-shadow: 0 0 0 1px #fff;

    &:hover {
      border-color: palette(blue, light);
    }
  }

  img {
    filter: grayscale(100%);
    opacity: .5;
  }

  .active a {
    border-color: palette(blue, light);
    box-shadow: 0 0 0 1px palette(blue, light);
    background-image: linear-gradient(180deg, #FFFFFF 0%, #F9F9F9 89%, #F2F2F2 100%);
  }
  .active a .button {
    background-color: transparent;
    color: palette(blue, light) !important;

    &::after {
      content: 'ed'; // changed "Select" to "Selected"
    }
  }
  .active a img {
    filter: initial;
    opacity: 1;
  }
}


// Course Content
// ----------------------------------------------------------------
.course-about {
  .content-sidebar {
    gap: 2em;
  }
  .bg-light-grey-mountains {
    padding-bottom: 10em;
  }
  .box h3 {
    color: $base-font-color;
  }
  h2:first-child,
  h3:first-child {
    margin-top: 0;
  }
}




// Testimonials
// ----------------------------------------------------------------

.course-testimonials-avatars {
  padding-top: 2em;

  .h1 {
    color: #fff;
  }
}

.testimonial-avatars-slider {
  margin-bottom: 1em;
  margin-top: -4em;
  position: relative;
  top: 3.5em;
  z-index: 10;

  .avatar-circle {
    --avatar-size: 4rem;
    border: 4px solid #fff;
    box-shadow: 2px 4px 0 rgba(0,0,0,.15);
    cursor: pointer;
    position: relative;

    @include breakpoint($med) {
      --avatar-size: 6rem;
    }

    @include breakpoint($med-lg) {
      --avatar-size: 8rem;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fff;
      opacity: .5;
    }
  }
}
.slick-current .avatar-circle {
  border-color: #000;

  &::after {
    opacity: 0;
  }
}

.course-testimonials .course-testimonials-avatars + .container {
  overflow: hidden; // Hide arrows when they're off-screen
}
.testimonials-slider {
  .slick-next { margin-right: -10%; right: 0; display: none !important; }
  .slick-prev { margin-left: -10%; left: 0; display: none !important; }
  @include breakpoint($med-lg) {
    .slick-next, .slick-prev { display: inline-block !important; }
  }
}

.course-testimonials .box {
  background-color: #edf8ff;

  * { max-width: none; } // fix for slider
}


// How it Works
// ----------------------------------------------------------------
.how-it-works {
  background-color: #fafafa;
}

.how-timeline {
  font-size: .9em;
  padding-top: 199px;
  position: relative;
  background-image: url(/mgs_assets/images/v4/pages/training/how-chevrons.svg),
                    url(/mgs_assets/images/v4/pages/training/how-chevrons.svg);
  background-repeat: no-repeat, no-repeat;
  background-position: -10% 1em,
                        110% 1em;
  

  @include breakpoint($lg) {
    &::before {
      content: '';
      width: 100%;
      height: 4px;
      background-color: #65bef6;
      position: absolute;
      top: 199px;
      left: 0;
    }
  
    > .container {
      display: grid;
      grid-template-columns: repeat(3,1fr);
      gap: 2rem;
    }
  }
}

.how-timeline-section {
  --border-color: #00c3a9;
  border-left: 4px solid var(--border-color);
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-bottom: 3em;
  position: relative;

  @include breakpoint($lg) {
    padding-bottom: 0;
  }

  &::before {
    content: '';
    width: 2em;
    height: 2em;
    border-radius: 50%;
    border: 4px solid var(--border-color);
    background-color: #fafafa;
    position: absolute;
    top: -1em;
    left: -1em;
    margin-left: -6px;
    margin-top: -3px;
  }

  h3 {
    text-transform: uppercase;
    color: #a6a6a6;
    font-size: 1.4em;
    margin: .75em 0;
    
    @include breakpoint($lg) {
      margin: 1.5em 0 .75em;
    }

    strong {
      color: var(--border-color);
      font-weight: 900;
    }
  }
}
.how-timeline-image {
  height: 195px;

  @include breakpoint($lg) {
    margin-top: -195px;
  }

  img {
    max-height: 100%;
    display: block;
    margin: auto;
  }
}

// Directory
// ----------------------------------------------------------------
.directory {
  background-image: url(/mgs_assets/images/v4/pages/training/bg-team.svg);
  background-position: 0 100%;
  background-repeat: repeat-x;
  padding-bottom: 10em;
}
.directory-photos {
  display: flex;
  align-content: center;
  justify-content: center;
  margin: -6em 0 0;
}
.directory-photos img {
  transform: rotate(-1deg);
  background: #D8D8D8;
  border: .4em solid #fff;
  box-shadow: 0 0 0 1px #e7e7e7, 0 5px 0 0 rgba(0,0,0,0.20);
  max-width: 18%;
  margin: 0 -.25em;
  aspect-ratio: 1/1;
  object-fit: cover;
  position: relative;

  &:nth-child(2) { transform: rotate(3deg); top: -1em;  }
  &:nth-child(3) { transform: rotate(-5deg);  }
  &:nth-child(4) { transform: rotate(7deg); }
  &:nth-child(5) { transform: rotate(-3deg); top: -.5em; }
}




// FAQ
// ----------------------------------------------------------------

.faq-wide {
  .faq-group {
    display: flex;
    align-items: start;
    flex-wrap: wrap;
    gap: 0 2em;
    padding-top: .5em;
    border-top: 2px solid palette(blue, x-light);
  
    & + .faq-group {
      margin-top: 2em;
    }
  }
  .faq-group-heading {
    margin: .5em 0;
    flex: 1 0 10ch;
    font-size: 1.2em;
    font-weight: 400;
    color: palette(blue);
  }
  .faq-group-questions {
    flex: 4;
    min-width: 300px;
  }
}